import React from 'react';

import AppTile from 'components/AppTile';
import demos from 'constants/demos';

const Home = () => (
  <div>
    {demos.map(props => (
      <AppTile {...props} />
    ))}
  </div>
);

export default Home;
