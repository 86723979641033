import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    height: '150px',
    width: '150px',
    cursor: 'pointer',
  },
});

const AppTile = ({ name, url }) => {
  const classes = useStyles();
  const onClick = () => (window.location.href = url);
  return (
    <Card className={classes.root} onClick={onClick}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AppTile;
